.downloader {
    .container {
        .page {
            display: flex;
            flex-direction: column;
            transition: all 0.2s;
            .options {
               
            }

            .progress-page {
                min-height: 150px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex-grow: 1;

                .downloading{
                    text-align: center;
                }
            }
        }
    }
}
