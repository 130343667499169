.datasets-overview {
    width: calc(100vw - #{$sidenav-collapsed-width});
    height: 100%;
    overflow: auto;

    .list-view {
        .item-container {
            min-width: 1520px;
            overflow: initial;
            .no-data-indicator {
                height: $list-row-height;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 60px;
            }
        }
        .sort-toolbar {
            min-width: 1520px;
            box-sizing: border-box;
        }
    }

    .dataset {
        color: $app-text-color;
        user-select: none;
        transition: all 0.2s;
        display: flex;
        padding-left: $general-side-padding;
        border-bottom: 1px solid $border-color;
        cursor: pointer;
        background-color: $app-bg-color-light;
        position: relative;

        &.selected {
            color: $primary-01;
        }

        &:hover {
            color: $primary-01;
        }

        &.cached {
            .geometry-icon {
                color: $primary-01;
            }
        }

        &.uncached {
            .geometry-icon {
                color: $error-bg;
            }
        }

        &.caching {
            .geometry-icon {
                color: $warning-bg;
            }
        }

        .overflow-text {
            max-width: 270px;
        }

        .dataset-table-cell {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: $list-row-height;
            padding-right: 8px;
            box-sizing: border-box;
            margin-right: 16px;
            &.geometry-icon {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                min-width: 44px;
            }
            &.name {
                flex-grow: 1;
                min-width: 273px;

                &:hover {
                    text-decoration: underline;
                }
            }

            &.schema {
                min-width: 83px;
                max-width: 83px;
                color: $app-text-color;
                text-transform: capitalize;

                .overflow-text {
                    max-width: 80px;
                    text-overflow: clip;
                    white-space: pre-wrap;
                }
            }

            &.date {
                min-width: 120px;
            }

            &.text-column {
                min-width: 104px;
            }

            &.database-name {
                width: 350px;
                min-width: 300px;
            }

            &.button {
                position: sticky;
                right: 0;
                color: #797979;
                transition: all 0.2s;
                align-self: flex-start;
                box-shadow: -2px 10px 15px 0 rgba(0, 0, 0, 0.04);
                background: white;
                padding: 0;
                margin: 0;

                svg {
                    background-color: $neutral-04;
                    color: black;
                    margin: 16px;
                    box-sizing: border-box;
                }

                .icon {
                    font-size: 24px;
                }

                &:hover {
                    background-color: #f1f3f4;
                }
            }
        }
    }
}

.menu-is-open .datasets-overview {
    width: calc(100vw - #{$sidenav-pinned-width});
}

.create-dataset-modal {
    .file-name {
        padding: 16px;
        border: 2px solid $app-bg-color-light;
        text-align: center;
        margin-bottom: 16px;
    }

    .file-error {
        padding: 16px;
        background-color: #db6a64;
        color: white;

        &:not(:first-child) {
            border-top: 2px solid #f1f0f0;
        }

        .title {
            margin-bottom: 8px;
        }
        .text {
            font-size: 14px;
            display: flex;
            align-items: center;

            .highlight {
                padding: 4px;
                margin: 0px 4px;
                background-color: white;
                color: #db6a64;
            }
        }
    }
}
