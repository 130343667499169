.groups-rootview {
    background-color: $app-bg-color-light;

    display: grid;
    grid-template-areas: "navigation sidebar map";

    height: 100%;
    width: 100vw;

    .sidebar {
        grid-area: sidebar;
        min-width: 500px;
        background-color: $app-bg-color;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

    .group-edit-container {
        grid-area: map;
        position: relative;
    }
}

.menu-is-closed .groups-rootview {
    grid-template-columns: $sidenav-collapsed-width min-content minmax(20%, 100%);
}

.menu-is-open .groups-rootview {
    grid-template-columns: $sidenav-pinned-width min-content minmax(20%, 100%);
}
