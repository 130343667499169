.raster-details {
    width: 500px;
    overflow: auto;
    background-color: $app-bg-color-light;

    .container {
        padding: 16px $general-side-padding;
        display: flex;
        flex-direction: column;
        overflow: auto;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        height: 100%;

        .section {
            padding: 0px 8px;
            margin-bottom: 8px;

            .section-container {
                .value {
                    padding: 16px;
                    background: white;
                    text-align: center;
                }
            }
        }

        .field {
            padding: 16px 0;

            input {
                height: auto;
            }

            &.flex {
                display: flex;
                justify-content: space-between;
                & > div {
                    width: 48%;
                }
            }

            &.flex-3 {
                display: flex;
                justify-content: space-between;
                & > div {
                    width: 31%;
                }
            }
            &.copyable {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .copy-button {
                min-width: 31px;
                height: 30px;
                margin-left: 20px;
                padding: 0 5px;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $secondary-01;
                img {
                    width: 20px;
                    height: 20px;
                }
                &:hover {
                    cursor: pointer;
                    background-color: rgba($secondary-01, 0.8);
                }
            }

            .readonly-field {
                padding: 8px 0;
            }
        }

        &.container--bottom {
            flex-direction: row;
            box-sizing: border-box;
            width: 100%;
            padding: 20px;
            margin-top: auto;
            align-items: center;
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
            display: flex;
            height: $datasets-bottom-section-height;
            overflow: initial;
        }
    }

    .back-btn {
        margin-right: 8px;
    }
}
