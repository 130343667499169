.edit-app-view {
    width: 500px;
    overflow: auto;
    background-color: $app-bg-color-light;

    .content {
        display: flex;
        flex-direction: column;
        box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2);
        height: 100%;
        overflow: auto;

        .layer-groups {
            width: 500px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            height: 100%;

            .list-container {
                overflow: auto;
                display: flex;
                flex-direction: column;
                height: 100%;
                background-color: white;
                .item-container {
                    overflow: hidden;
                    flex-grow: 1;
                }

                .adding-buttons {
                    display: flex;
                    padding: 12px 12px 0;

                    .add-group-button {
                        margin-left: auto;
                    }

                    .left-icon {
                        margin-right: 8px;
                    }
                }

                .search-no-bg {
                    background-color: transparent;
                }
            }

            .list {
                overflow: auto;
                display: flex;
                flex-direction: column;
                min-height: 100px;
                flex-grow: 1;

                .group-root {
                    box-shadow: 0 2px 12px 1px rgba(60, 62, 66, 0.1);
                    border-radius: 3px;

                    .group {
                        display: flex;
                        align-items: center;
                        padding: 0px 16px;
                        height: 48px;
                        cursor: pointer;

                        &:hover {
                            color: $primary-01;

                            .dragIndicatorIcon {
                                color: $primary-01;
                            }
                        }

                        &.selected {
                            color: $primary-01;
                        }
                    }
                    .layer {
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
                }
            }

            .sub-group-root {
                .sub-group {
                    display: flex;
                    align-items: center;
                    height: 24px;
                    padding: 8px 0px;
                    cursor: pointer;

                    &.selected {
                        margin-left: 30px;
                    }

                    &:hover {
                        color: $primary-01;

                        .dragIndicatorIcon {
                            color: $primary-01;
                        }
                    }
                }
                .layer {
                    padding-left: 16px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            }

            .dragIndicatorIcon {
                font-size: 20px;
                color: #a2a2a2;
                margin-right: 8px;
            }

            .layer {
                display: flex;
                align-items: center;
                background-color: white;
                height: 24px;

                &:hover {
                    color: $primary-01;

                    .dragIndicatorIcon {
                        color: $primary-01;
                    }
                }

                &.selected {
                    background-color: $primary-01;
                }
            }
        }

        .actions {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            min-height: 32px;
            max-height: 32px;
            background-color: $app-bg-color-light;

            p {
                flex-grow: 1;
            }
        }

        .app-settings-view {
            background: white;
            display: flex;
            flex-direction: column;
            overflow: auto;

            .left-icon {
                margin-right: 8px;
            }

            .icon {
                margin-left: 8px;
                display: flex;
            }

            .settings-title {
                padding: 16px;
            }
            .settings-container {
                padding: $sidepanel-top-bottom-padding $general-side-padding;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;

                .name {
                    margin-bottom: 12px;
                }

                .section {
                    margin: 8px 0;
                    box-shadow: 0 2px 12px 1px rgba(60, 62, 66, 0.1);

                    .section-header {
                        font-size: 16px;
                        font-weight: bold;
                        padding-top: 8px;
                        padding-bottom: 16px;
                    }

                    .section-content {
                        .subtitle {
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 22px;
                            margin-bottom: 12px;
                        }

                        .enabled-tools {
                            display: flex;
                            flex-wrap: wrap;
                            padding-bottom: 8px;

                            .tool {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                margin-right: 12px;
                                margin-bottom: 16px;
                                border: 0.5px solid $secondary-01;
                                border-radius: 2px;
                                padding: 2px 8px;
                                font-size: 14px;
                                font-weight: 600;
                                letter-spacing: 0.61px;
                                text-transform: capitalize;

                                &:hover {
                                    background-color: $neutral-06;
                                }

                                &.enabled {
                                    background-color: $secondary-01;
                                    color: $primary-01;
                                    border: 0.5px solid $primary-01;
                                }
                            }
                        }
                        .enable-sidebar-collaps {
                            display: flex;

                            .checkbox {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 14px;
                                font-weight: 600;
                                margin-right: 30px;

                                .left-icon {
                                    color: $muted-color;
                                    border-radius: 10px;

                                    &:hover {
                                        background: radial-gradient($neutral-04 50%, transparent 50%, transparent);
                                    }
                                }

                                &.checked {
                                    .left-icon {
                                        color: $primary-01;
                                        background: radial-gradient($secondary-01 50%, transparent 50%, transparent);
                                    }
                                }
                            }
                        }
                    }

                    .shadow-box {
                        box-shadow: 0 2px 12px 1px rgba(60, 62, 66, 0.1);
                        padding: 8px 16px 10px;
                        border-radius: 3px;
                        box-sizing: border-box;
                    }

                    .flex {
                        display: flex;
                        padding-bottom: 16px;
                        & > div {
                            min-width: 90px;
                            margin-right: 20px;
                        }
                    }

                    .info-section {
                        display: flex;
                        color: $neutral-02;
                        padding-bottom: 16px;

                        .info-section__icon {
                            transform: scale(0.6) translate(-6px, -6px);
                        }

                        .info-section__text {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 19px;
                            letter-spacing: 0.61px;
                        }
                    }

                    .update-button {
                        font-size: 16px;
                        font-weight: 600;
                    }

                    &.scroll {
                        overflow: auto;
                        display: flex;
                        flex-direction: column;
                        min-height: 54px;
                        max-height: 600px;
                    }
                }

                .field {
                    padding-bottom: 16px;

                    input {
                        height: auto;
                    }

                    &.flex {
                        display: flex;
                        justify-content: space-between;
                        & > div {
                            width: 48%;
                        }
                    }
                    &.flex-3 {
                        display: flex;
                        justify-content: space-between;
                        & > div {
                            width: 31%;
                        }
                    }
                    &.copyable {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .copy-button {
                        min-width: 31px;
                        height: 30px;
                        margin-left: 20px;
                        padding: 0 5px;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $secondary-01;
                        img {
                            width: 20px;
                            height: 20px;
                        }
                        &:hover {
                            cursor: pointer;
                            background-color: rgba($secondary-01, 0.8);
                        }
                    }
                }

                .readonly-field {
                    font-size: 14px;

                    .readonly-field__label {
                        font-weight: bold;
                    }
                }
            }
        }

        .bottom-container {
            flex-direction: row;
            box-sizing: border-box;
            width: 100%;
            padding: 20px;
            margin-top: auto;
            align-items: center;
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
            display: flex;
            height: $datasets-bottom-section-height;
            overflow: initial;
            z-index: 1;

            .left-icon {
                margin-right: 8px;
            }
        }
    }
    .edit-view-container {
        width: 500px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        background-color: $app-bg-color-light;
        position: absolute;
        z-index: 3;
        height: calc(100% - #{$logo-div-height});

        .tabs {
            display: flex;
            background-color: $app-bg-color-light;
        }

        .top-bar {
            display: flex;
            background-color: $app-bg-color-light;

            .title {
                padding: 12px 16px;
            }
        }

        .close-button {
            background-color: $app-bg-color;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
                background-color: $primary-01;
                color: white;
            }
        }

        .edit-page {
            padding: $general-side-padding;

            .dataset-info {
                padding: $general-side-padding;
                background-color: $app-bg-color-light;

                .grow {
                    flex-grow: 1;
                }
            }

            .checkbox {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .field {
                padding: 16px 0;

                &.flex {
                    display: flex;
                    justify-content: space-between;

                    > div {
                        width: 48%;
                    }
                }
            }

            .container:not(:first-child) {
                margin-top: 8px;
            }
        }
    }

    .divider {
        background-color: $app-bg-color;
        height: 2px;
    }
    .tools {
        display: flex;
        flex-direction: column;

        overflow: auto;
        position: absolute;
        left: 501px;
        top: 150px;
        bottom: 0px;
        z-index: 10;
    }
}

.basemap-picker {
    .list-view {
        padding: 0;
    }

    .raster-page {
        .title {
            padding: 16px 0px;
        }
    }

    .url-page {
        .title {
            padding: 16px 0px;
        }
    }

    .basemap-container {
        position: relative;
        margin: -12px 0;

        .draggable-section {
            background-color: $app-bg-color-light;
        }

        .basemap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $border-color;
            padding: 12px 0;

            .drag-indicator {
                margin-right: 12px;
            }

            .map-preview {
                width: 54px;
                margin-right: 12px;
                display: flex;

                img {
                    max-width: 100%;
                }
            }

            .type-chip {
                margin-left: auto;
                margin-right: 24px;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.11px;
                line-height: 18px;
                text-transform: capitalize;

                &.vector {
                    background: #e4f5f2;
                    color: #3d7f74;
                }

                &.raster {
                    background: #eaeaff;
                    color: #504ce1;
                }
            }

            .menu-button {
                border-radius: 3px;
                height: 23.5px;
                width: 25px;
                background-color: $neutral-04;
                color: black;
            }

            .basemap-title {
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
            }

            .basemap-url {
                display: flex;
                align-items: center;
                max-width: 160px;
                div {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    color: $primary-01;
                    font-weight: 600;
                }

                .copy-button {
                    min-width: 30px;
                    height: 30px;
                    border-radius: 3px;

                    img {
                        width: 16px;
                    }
                }
            }
        }

        .filler {
            display: none;
        }

        .row:last-of-type hr {
            display: none;
        }
    }

    .button-container {
        position: sticky;
        bottom: 0;
        background-color: $app-bg-color-light;
        padding: 8px 16px 16px;
        border-top: 1px solid $border-color;
        margin: 12px -16px 0;

        .button {
            font-size: 14px;
            font-weight: 600;
            margin-top: 4px;
        }
    }
}

.add-data-tool {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: 2px solid white;

    .flex-grow {
        flex-grow: 1;
    }

    .tabs {
        display: flex;
        background-color: $app-bg-color-light;
    }

    .close-button {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        cursor: pointer;
        z-index: 2;
        box-shadow: 0 5px 2px -4px rgba(0, 0, 0, 0.1);

        &:hover {
            background-color: $primary-01;
            color: white;
        }
    }

    .page-container {
        flex-grow: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        background-color: $app-bg-color;

        .upload-button {
            padding: 16px;
            padding-bottom: 0px;
        }
    }

    .dataset-list {
        display: flex;
        flex-direction: column;
        overflow: auto;
        width: 550px;
        height: 100%;
        background-color: $app-bg-color;

        .upload-button {
            padding: 16px;
            padding-bottom: 0px;
        }
    }

    .list-view .item-container .item .container .name {
        overflow: hidden;

        .overflow-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.projection-picker {
    .projections {
        display: flex;
        flex-wrap: wrap;

        .projection {
            margin-right: 16px;
            margin-bottom: 16px;
            padding: 8px 10px;
            display: flex;
            align-items: center;
            border-radius: 18px;
            background-color: rgba(227, 228, 229, 0.7);
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.13px;
            .icon {
                cursor: pointer;
            }
        }
    }

    .button-container {
        position: sticky;
        bottom: 0;
        background-color: $app-bg-color-light;
        padding: 8px 16px 16px;
        border-top: 1px solid $border-color;
        margin: 12px -16px 0;

        .button {
            font-size: 14px;
            font-weight: 600;
            margin-top: 4px;
        }
    }

    .button {
        font-size: 14px;
        font-weight: 600;

        span {
            display: flex;
            align-items: center;
        }
    }
}

.projection-dialog {
    .MuiDialog-paper {
        background-color: $app-bg-color;
    }

    .dialog-header {
        display: flex;
        align-items: center;
        padding: 16px;
        background-color: $app-bg-color-light;
        .dialog-title {
            background-color: $app-bg-color-light;
            flex-grow: 1;
            margin-right: 16px;
        }
    }

    .autocomplete-container {
        padding: 16px;
    }
}

.MuiAutocomplete-popper {
    background-color: $app-bg-color;
    .MuiAutocomplete-paper {
        box-shadow: none;
    }
}
