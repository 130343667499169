.group-overview {
    width: calc(100vw - #{$sidenav-collapsed-width});
    overflow: auto;
    .group {
        color: $app-text-color;
        user-select: none;
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: $app-bg-color-light;
        margin-bottom: 4px;
        border-right: 2px solid transparent;
        cursor: pointer;

        .name {
            flex-grow: 1;
            padding-left: 16px;

            &:hover {
                color: $primary-01;
            }
        }

        .button {
            transition: all 0.2s;
            color: $icon-color;
            font-size: 16px;
            border-left: 4px solid $app-bg-color;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;

            &:hover {
                color: white;
                background-color: rgb(88, 88, 88);
            }
        }

        &.selected {
            border-color: $primary-01;
        }

        &:hover {
            border-color: $primary-01;
        }
    }
}

.menu-is-open .group-overview {
    width: calc(100vw - #{$sidenav-pinned-width});
}
