#root,
.app,
html,
body {
    margin: 0;
    height: 100%;
    width: 100%;
    display: flex;
}

.app-wrapper {
    height: 100%;
    flex-grow: 1;
}

#map {
    width: 100%;
    height: 100vh;
}

.map,
.mapboxgl-map:focus,
canvas {
    outline: none;
}
