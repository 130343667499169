.back-arrow {
    display: flex;
}
.dataset-details {
    width: 500px;
    height: 100%;
    overflow: auto;
    background-color: $app-bg-color-light;
    position: relative;
    .cache-status-container {
        transition: all 0.2s;
        text-transform: capitalize;
        width: fit-content;
        height: 30px;
        border-radius: 15px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.cached {
            background-color: #e5f2e4;
            color: $success-bg;
        }

        &.uncached {
            background-color: #fde3e2;
            color: $error-bg;
        }

        &.caching {
            background-color: #fff3e0;
            color: $semantic-03;
        }
    }

    .styled-tab {
        padding: 0 0 0 $general-side-padding;
    }
    .container {
        padding: $sidepanel-top-bottom-padding $general-side-padding;
        overflow: auto;
        height: 100%;

        .info-section {
            display: flex;
            margin-bottom: 4px;

            &__icon {
                transform: scale(0.6) translate(-6px, -6px);
                color: $icon-color-light;
            }
        }

        .field {
            padding: 16px 0;

            &.flex {
                display: flex;
                justify-content: space-between;
                & > div {
                    width: 48%;
                }
            }
            &.flex-3 {
                display: flex;
                justify-content: space-between;
                & > div {
                    width: 31%;
                }
            }
            &.copyable {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .copy-button {
                min-width: 31px;
                height: 30px;
                margin-left: 20px;
                padding: 0 5px;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $secondary-01;
                img {
                    width: 20px;
                    height: 20px;
                }
                &:hover {
                    cursor: pointer;
                    background-color: rgba($secondary-01, 0.8);
                }
            }
        }
        &.container--bottom {
            flex-direction: row;
            box-sizing: border-box;
            width: 100%;
            padding: 20px;
            margin-top: auto;
            align-items: center;
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
            display: flex;
            height: $datasets-bottom-section-height;
            overflow: initial;
        }
    }

    .page {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        height: 100%;
    }

    .propertiesContainer {
        min-height: 280px;
    }

    .shadow-section {
        box-shadow: 0 2px 12px 1px rgba(60, 62, 66, 0.1);
        border-radius: 3px;
        margin: 8px 0;

        .items {
            overflow: auto;
        }

        &__header {
            height: 54px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            cursor: pointer;
        }

        &__container {
            padding: 16px;

            .generated-tiles,
            .zoom-level {
                width: 100%;

                &__header {
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                    color: $neutral-01;
                }

                &__value {
                    font-size: 18px;
                    font-weight: 800;
                    letter-spacing: 0.17px;
                    line-height: 24px;
                    color: $neutral-01;
                }
            }
        }

        &.scroll {
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-bottom: 0;
        }

        .flex {
            display: flex;
            flex-grow: 1;
            align-items: center;
        }

        .subtitle {
            padding-top: 8px;
        }

        .text-container {
            padding: 8px;
        }

        .slider-container {
            padding: 0px 23px 16px 23px;
            .slider-footer {
                display: flex;
                margin-top: 8px;
                justify-content: space-between;
            }
        }
    }

    .checkbox-header {
        color: lightgray;
        font-size: 12px;
        margin-bottom: 5px;
    }

    .checkbox-container {
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        overflow: auto;

        .checkbox-item {
            display: flex;
            flex-grow: 1;
            align-items: center;
            padding: 0 16px;
            margin-bottom: 4px;

            &:last-of-type {
                border: none;
            }
            .label {
                font-size: 14px;
                color: $app-text-color;
                padding: 20.5px 8px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .flex {
                display: flex;
                align-items: center;
                flex-grow: 1;
                height: 100%;
                width: 100%;
                max-width: 211px;
                cursor: pointer;
            }
        }
    }
    .all-checkbox {
        display: flex;
        align-items: center;
        padding: 0 16px;

        .all-box {
            width: 100%;
        }

        .label {
            width: 100%;
            padding: 20.5px 8px;
        }
    }

    .tag-list-header {
        margin-left: 8px;
    }

    .tag-list {
        padding: 16px 0px;
        display: flex;
        flex-wrap: wrap;

        .tag {
            border: 1px solid transparent;
            padding: 0 10px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            margin-right: 4px;
            margin-bottom: 4px;
            cursor: pointer;
            transition: all 0.3s;
            height: 30px;
            font-size: 13px;

            .text {
                color: grey;
            }

            &.enabled {
                .text {
                    color: white;
                }
            }

            &:hover {
                filter: grayscale(0.25);
            }
        }
    }
}
