.users-overview {
    width: calc(100vw - #{$sidenav-collapsed-width});
    height: 100%;
    overflow: auto;
    .user {
        color: $app-text-color;
        user-select: none;
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: $app-bg-color-light;
        margin-bottom: 4px;
        border-right: 2px solid transparent;
        overflow: auto;
        cursor: pointer;

        &:hover {
            cursor: pointer;
            border-color: $primary-01;
        }

        .name {
            flex-grow: 1;
            padding-left: 16px;
        }

        .button {
            transition: all 0.2s;
            color: $icon-color;
            font-size: 16px;
            border-left: 4px solid $app-bg-color;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            .icon {
                font-size: 24px;
            }
            &:hover {
                color: white;
                background-color: $primary-01;
            }
        }

        &.selected {
            border-color: $primary-01;
        }
    }

    .list-view .scroll-container .sort-toolbar .button.name {
        min-width: initial;
    }
}

.menu-is-open .users-overview {
    max-width: calc(100vw - #{$sidenav-pinned-width});
}

.user-edit-view {
    .header {
        padding: 16px;
        background-color: $primary-01;

        h6 {
            color: white;
        }
    }

    .container {
        .section:not(:last-child) {
            margin-bottom: 8px;
        }

        .field {
            margin: 8px 0px;
        }

        .section {
            &.flex {
                display: flex;

                .button {
                    margin-right: 8px;
                }
            }
        }
    }
}
