.group-edit-view {
    min-width: 672px;

    .container {
        .users {
            padding: 16px;

            .user {
                cursor: pointer;
                padding: 16px;
                color: white;
                border: 1px solid grey;

                &:hover {
                    border: 1px solid #008b8b;
                }
            }
        }

        .resourcePermissionItem {
            display: flex;
            align-items: center;
            padding: 8px;
            padding-left: 16px;
            padding-right: 16px;
            color: $app-text-color;
            background-color: $app-bg-color-light;
            border-bottom: $border-color solid 1px;

            .name {
                font-size: 16px;
                flex-grow: 1;
                width: 200px;
                margin-right: 16px;
            }

            .checkbox-container {
                display: flex;
                align-items: center;
                flex-direction: column;
                .radio-label {
                    font-size: 12px;
                }
            }
        }

        .list {
            background-color: $app-bg-color;
            height: 500px;
            overflow: auto;
        }

        .section {
            margin-bottom: 16px;

            &.flex {
                display: flex;
            }
        }
    }
}
