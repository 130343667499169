.mapboxgl-ctrl-attrib .mapbox-improve-map {
    display: none;
}

.mapboxgl-ctrl-attrib-inner {
    font-size: 10px;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-scale {
    margin: 0px 5px 5px 0px;
}
