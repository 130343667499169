.uploader {
    .container {
        position: relative;
        display: flex;
        justify-content: center;
        overflow: auto;

        .drag-overlay {
            background-color: $secondary-01;
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 9999999;
            svg {
                margin-bottom: 10px;
                font-size: 2rem;
            }
            div {
                color: $primary-01;
                font-size: 16px;
                font-weight: bold;
            }
            &.big {
                svg {
                    font-size: 4rem;
                }
                div {
                    font-size: 30px;
                }
            }
        }
    }

    .page {
        min-height: 400px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .actions {
            display: flex;
            .flex-grow {
                flex-grow: 1;
            }
        }

        .file-uploader {
            width: 300px;
            min-height: 450px;
            display: flex;
            flex-direction: column;
            border-right: 1px solid $primary-01;
            .title {
                margin-bottom: 8px;
            }

            .csv-upload-info {
                padding: 16px;
                height: 100%;
                display: flex;
                flex-direction: column;

                .grow {
                    flex-grow: 1;
                }
            }

            .dataset-upload-info {
                padding: 16px;
                height: 100%;
                display: flex;
                flex-direction: column;

                .grow {
                    flex-grow: 1;
                }
            }

            .file-upload {
                flex-grow: 1;

                .progress-page {
                    height: 300px;
                    background-color: $app-bg-color-light;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .text {
                        text-align: center;
                    }
                }
            }
        }

        .bottom-container {
            border: 1px solid $border-color;
            border-radius: 3px;
            padding: 20px;
            position: relative;

            &.flex-column {
                display: flex;
                flex-direction: column;
            }

            img {
                margin-right: 10px;
            }

            &.uploaded {
                padding: 16px;
                margin: 0 -16px -16px -16px;
                border-bottom: initial;
                position: initial;
            }

            &.dragging {
                * {
                    pointer-events: none;
                }
            }
        }

        .upper-container {
            display: flex;
            align-items: center;

            .datasets-nr {
                color: $app-text-color;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.61px;
                padding-right: 16px;
                border-right: 1px solid $secondary-01;
            }
        }

        .icon {
            margin-right: 8px;
        }

        .upload-all-button {
            border-radius: 3px;
            margin-left: 16px;
            padding: 8px 10px;

            &:hover {
                background-color: $secondary-01;
            }

            img {
                width: 20px;
                margin-right: 8px;
            }

            .upload-all-button__text {
                color: $primary-01;
                font-size: 14px;
                font-weight: bold;
                line-height: 20px;
            }
        }

        .upload-list {
            max-height: 636px;
            flex-grow: 1;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 30px;
            overflow: auto;

            .title {
                margin-bottom: 8px;
            }

            .no-uploads-indicator {
                height: 100%;
                display: flex;
                justify-content: center;

                pointer-events: none;

                h6 {
                    pointer-events: none;
                    display: flex;
                    padding: 8px 0;
                    min-width: 650px;
                    align-items: center;
                }

                .icon {
                    width: 20px;
                    margin-right: 8px;
                    pointer-events: none;
                    color: $neutral-02;
                }
            }

            .upload {
                border-bottom: 1px solid $border-color;

                &:last-of-type {
                    border: none;
                }

                .flex-grow {
                    flex-grow: 1;
                }

                .type-chip {
                    margin: 16px 16px 16px 0;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0.11px;
                    line-height: 18px;

                    &.vector {
                        background: #e4f5f2;
                        color: #3d7f74;
                    }

                    &.raster {
                        background: #eaeaff;
                        color: #504ce1;
                    }
                }

                .upload-container {
                    min-width: 1220px;
                    display: flex;
                    padding: 20px 0;

                    .button-container {
                        display: flex;
                    }
                }

                .uploading-container {
                    padding: 20px 0;
                    min-width: 1220px;
                    .uploading-info {
                        display: flex;
                        align-items: center;

                        .section {
                            margin-right: 8px;
                            min-width: 250px;
                            max-width: 390px;
                        }
                        .status {
                            text-transform: capitalize;
                            position: absolute;
                            color: $success-bg;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0.61px;
                            line-height: 19px;
                        }

                        .icon {
                            padding: 0px 8px;
                            width: 56px;
                            height: 56px;
                            border-radius: 5px;
                            margin-right: -4px;
                        }

                        .file-name {
                            padding: 16px;

                            background-color: $app-bg-color-light;
                            min-width: 150px;
                            max-width: 150px;
                            text-align: center;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            cursor: pointer;
                            margin-right: 4px;
                        }

                        .error-icon {
                            font-size: 56px;
                            color: $error-bg;
                            cursor: pointer;
                        }

                        .progress {
                            flex-grow: 1;
                            height: 32px;
                            display: flex;
                            position: relative;
                            font-weight: bold;
                            margin: 0 10px;
                            .progress-bar {
                                flex-grow: 1;
                                height: 32px;
                                .MuiLinearProgress-barColorPrimary {
                                    background-color: #e5f2e4;
                                }
                            }

                            &.warning {
                                background-color: $app-bg-color;
                                mix-blend-mode: unset;
                                .progress-bar {
                                    .MuiLinearProgress-barColorPrimary {
                                        background-color: #fce2e1;
                                    }
                                }

                                .status {
                                    color: $error-bg;
                                }
                            }

                            &.error {
                                background-color: $app-bg-color;
                                mix-blend-mode: unset;
                                .progress-bar {
                                    .MuiLinearProgress-barColorPrimary {
                                        background-color: #fce2e1;
                                    }
                                }

                                .status {
                                    color: $error-bg;
                                }
                            }
                        }

                        .readonly-field {
                            font-size: 14px;
                            line-height: initial;

                            .readonly-field__label {
                                font-weight: bold;
                            }

                            .readonly-field__text {
                                font-weight: initial;
                            }
                        }
                    }
                }

                .section {
                    margin: 0 10px;
                    min-width: 192px;
                    max-width: 390px;
                    background-color: white;

                    &.grow {
                        flex-grow: 1;
                    }

                    &.checkbox {
                        display: flex;
                        justify-content: center;
                        min-width: fit-content;
                    }
                }

                .delete-button,
                .upload-button {
                    width: 32px;
                    height: 32px;
                    border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 16px 10px;
                }

                .delete-button {
                    background: #fce2e1;
                    color: $error-bg;

                    &:hover {
                        background: #ffefee;
                        color: rgba($error-bg, 0.8);
                    }
                }

                .upload-button {
                    margin-left: auto;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .cancel-button {
                    width: 56px;
                    height: 56px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    cursor: pointer;
                    transition: all 0.2s;
                    margin-left: 4px;

                    &:disabled {
                        background-color: rgba(0, 0, 0, 0.12);
                    }

                    &:hover {
                        filter: grayscale(50%);
                    }

                    &.cancelled {
                        background-color: $warning-bg;
                    }

                    &.failed {
                        background-color: $error-bg;
                    }
                }
            }
        }
    }
}

.replacer {
    .page {
        display: flex;
        flex-direction: column;
        min-height: 350px;
        height: auto;

        .file-upload-container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        .errors {
            max-height: 200px;
            overflow: auto;

            .error {
                padding: 8px;
                background-color: $app-bg-color-light;
                margin-bottom: 4px;
                display: flex;
                align-items: center;
                .text {
                    color: $error-bg;
                    margin-right: 8px;
                    flex-grow: 1;
                }

                .icon {
                    font-size: 18px;
                    color: $error-bg;
                }
            }
        }

        .progress-page {
            height: 300px;
            background-color: $app-bg-color-light;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .text {
                text-align: center;
            }
        }

        .upload-button {
            margin-top: 8px;
        }
    }
    .MuiDialog-paper {
        overflow-x: auto;
    }
}

.file-upload {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .multi-switch {
        margin-bottom: 8px;
    }

    .file-upload-box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $app-bg-color-light;
        cursor: pointer;
        transition: all 0.2s;
        flex-grow: 1;
        height: 250px;

        .pointer-events-none {
            pointer-events: none;
        }

        &.dragging {
            background-color: $primary-01;

            .text {
                color: white;
            }

            .icon {
                color: white;
            }

            .file-name {
                color: white;
            }
        }

        &:hover {
            background-color: $primary-01;

            .text {
                color: white;
            }

            .icon {
                color: white;
            }

            .file-name {
                color: white;
            }
        }

        .text {
            color: $app-text-color;
            text-align: center;
        }

        .icon {
            margin-top: 8px;
            font-size: 36px;
            color: $primary-01;
        }

        .file-name {
            margin-top: 8px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $primary-01;
        }

        .button {
            margin-top: 16px;
        }
    }

    .file-upload-box2 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $app-bg-color-light;
        cursor: pointer;
        transition: all 0.2s;
        flex-grow: 1;
        height: 250px;

        .pointer-events-none {
            pointer-events: none;
        }

        &.dragging {
            background-color: $primary-01;

            .text {
                color: white;
            }

            .icon {
                color: white;
            }

            .file-name {
                color: white;
            }
        }

        .text {
            color: $app-text-color;
            text-align: center;
        }

        .icon {
            margin-top: 8px;
            font-size: 36px;
            color: $primary-01;
        }

        .file-name {
            margin-top: 8px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $primary-01;
        }

        .button {
            margin-top: 16px;
        }
    }
}

.pointer-hover:hover {
    cursor: pointer;
}
