.settings-rootview {
    background-color: $app-bg-color-light;

    display: grid;
    grid-template-areas: "navigation sidebar map";

    height: 100%;
    width: 100vw;
    .sidebar {
        grid-area: sidebar;
        min-width: 500px;
        background-color: $app-bg-color;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
}

.settings-edit-container {
    grid-area: map;
    background-color: $app-bg-color-light;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 48px 0;
}

.menu-is-closed .settings-rootview {
    grid-template-columns: $sidenav-collapsed-width min-content minmax(20%, 100%);
}

.menu-is-open .settings-rootview {
    grid-template-columns: $sidenav-pinned-width min-content minmax(20%, 100%);
}
