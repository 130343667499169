@import "toastr-overrides.scss";

.no-margin-progress {
    margin-bottom: -4px;
    z-index: 2;
}

input:-internal-autofill-selected {
    background-color: transparent;
}

.map-container {
    width: 80%;
    display: flex;
    position: relative;

    &.full {
        width: 100%;
    }
}

.side-bar-container {
    width: 20%;
    display: flex;
    height: 100%;

    &.hide {
        width: 0% !important;
    }
}

.rc-slider-tooltip {
    z-index: 9999;
}

.rc-slider {
    margin-top: 15px;

    .rc-slider-handle {
        background-color: white;
        border-color: white;
        box-shadow: 0 0 5px $primary-01;

        &:hover {
            border-color: $primary-01;
        }
    }

    .slider-footer {
        justify-content: space-between;
        align-items: center;
        display: flex;
    }

    .rc-slider-track {
        background-color: $primary-01;
    }
}

::-webkit-scrollbar-track {
    background-color: white;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
}

::-webkit-scrollbar-track:vertical {
    border-top: white;
}

::-webkit-scrollbar-corner {
    border: none;
    background-color: white;
}

::-webkit-scrollbar {
    width: 22px;
    height: 30px;
}

::-webkit-scrollbar-thumb {
    background-color: $neutral-04;
    border-radius: 100px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:vertical {
    border: 8px solid rgba(0, 0, 0, 0);
}

.MuiMenu-paper {
    background-color: $app-bg-color-light !important;
    border: 2px solid $app-bg-color;

    color: white !important;

    .MuiMenu-list {
        padding: 0;
    }

    .menu-button {
        padding: 8px;
        font-weight: 600;
        .icon {
            font-size: 20px;
            color: $neutral-02;
            padding-right: 16px;
        }
    }
}

.mapboxgl-ctrl-attrib-button {
    display: none;
}
