$sidenav-collapsed-width: 60px;
$sidenav-pinned-width: 180px;
$sidepanel-top-bottom-padding: 24px;
$general-side-padding: 20px;
$datasets-bottom-section-height: 80px;
$header-height: 48px;
$logo-div-height: $header-height;
$list-row-height: 52px;

:export {
    generalSidePadding: $general-side-padding;
    headerHeight: $header-height;
    logoDivHeight: $logo-div-height;
}
