// Display utilities

.hidden {
    display: none;
}

// Flex utilities

.flex {
    display: flex;
}

.flex-grow {
    flex-grow: 1;
}

// Other utilities

.rotate180 {
    transform: rotate(180deg);
}

.flip {
    transform: scale(1, -1);
}

.border-bottom {
    border-bottom: 1px solid $border-color;
}

.button-icon {
    margin-right: 8px;
}

.light-grey-border {
    border-bottom: 1px solid $border-color;
}
