.sidebar {
    z-index: 2;

    .branding-title {
        flex-grow: 1;
        color: white;
    }

    .logo {
        height: 23px;
    }

    .seperater {
        flex-grow: 1;
    }

    .toolbar {
        background-color: $app-bg-color-light;
    }

    .header {
        display: flex;
    }
}

.sidebar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .title {
        flex-grow: 1;
        color: white;
    }

    .subtitle {
        flex-grow: 1;
    }

    .header {
        padding: 0 $general-side-padding;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        display: flex;
        border-top: 1px solid $border-color;
        box-sizing: border-box;
        min-height: 50px;

        h6 {
            color: $app-text-color;

            flex-grow: 1;
        }

        .button {
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            height: $list-row-height;
            width: 52px;
            transition: 0.2s all;
            cursor: pointer;

            &:hover {
                background-color: #4d5767;
            }
        }
    }

    .content-container {
        display: flex;
    }

    .items-container {
        margin: 0px 16px;

        overflow: auto;
    }
}
