.notifications {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border-left: 2px solid transparent;
    background-color: $app-bg-color;
    z-index: 1100;
    width: 0px;
    overflow: hidden;
    transition: all 0.2s;
    &.open {
        width: 500px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        border-left: 2px solid $primary-01;
        .notification-button {
            cursor: pointer;
            color: #000000;
            background-color: transparent;
            box-shadow: none;

            &:hover {
                color: $app-bg-color-dark;
            }
        }
    }

    .notifications-title {
        padding: 8px 24px;
        padding-left: 48px;
        display: flex;
        background-color: $primary-01;
        align-items: center;

        h6 {
            text-align: center;
            color: white;
            flex-grow: 1;
        }
    }

    .notifications-container {
        padding: 16px;
        overflow: auto;
    }

    .notification {
        background-color: $app-bg-color-light;
        border-bottom: none;
        &:not(:last-child) {
            margin-bottom: 8px;
        }

        .title-container {
            display: flex;
            align-items: center;
            padding: 0px 12px;
            padding-right: 0px;

            .title {
                padding-top: 18px;
                font-size: 18px;
                text-transform: capitalize;
                color: $primary-01;
                word-break: break-word;
            }
            .error-icon {
                padding-top: 18px;
                padding-right: 14px;
            }

            .grow {
                flex-grow: 1;
            }

            .cancel-button {
                height: 42px;
                width: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: background-color 0.2s;
                color: $primary-01;

                &:hover {
                    background-color: $app-bg-color;
                }
            }
        }

        .info {
            padding: 8px 16px;
            display: flex;
            align-items: center;

            .text {
                flex-grow: 1;
            }
        }

        .progress-bar {
            background-color: rgba($primary-01, 0.2);
        }
    }

    .icon {
        cursor: pointer;
        &:hover {
            color: gray;
        }
    }
}

.notification-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #000000;
    height: 100%;

    .icon {
        font-size: 27px;
    }

    &:hover {
        color: $app-bg-color-dark;
    }
}
