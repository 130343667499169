.datasets-rootview {
    background-color: $app-bg-color;

    display: grid;
    grid-template-areas: "navigation sidebar map";

    height: 100%;
    width: 100vw;

    .sidebar {
        grid-area: sidebar;
        min-width: 500px;
        background-color: $app-bg-color;
        display: flex;
        flex-direction: column;
        overflow: auto;
        transition: all 0.2s;

        &.closed {
            min-width: 0px;
            width: 0;
        }
    }

    .map {
        grid-area: map;
        position: relative;
    }

    .map-data-switch {
        display: flex;
        align-items: center;
        color: $app-text-color;
        padding: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: $app-bg-color;
    }
}

.menu-is-closed .datasets-rootview {
    grid-template-columns: $sidenav-collapsed-width min-content minmax(20%, 100%);
}

.menu-is-open .datasets-rootview {
    grid-template-columns: $sidenav-pinned-width min-content minmax(20%, 100%);
}
