@keyframes onclick-glow {
    from {
    }
    to {
    }
}

.navigation-menu {
    width: $sidenav-collapsed-width;
    height: 100%;
    grid-area: navigation;
    background-color: $primary-01;
    box-shadow: 15px 0px 15px -15px rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 1101;

    .menu-is-open & {
        width: $sidenav-pinned-width;
    }

    .header {
        width: 100%;
        height: 48px;
        background-color: $primary-04;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 255, 255, 0.04);
        }

        .header__item {
            display: flex;
            align-items: center;
            color: $white;

            &.is-active {
                opacity: 1;
            }

            .item__icon {
                padding: 0 18px;
                display: flex;
                align-items: center;
            }

            .item__label {
                opacity: 0.7;
            }
        }
    }

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

// Menu items

.nav-item {
    width: 100%;
    height: 50px;
    background-color: $primary-01;
    display: flex;
    align-items: center;
    transition: 0.2s all;
    cursor: pointer;
    color: white;
    opacity: 0.7;

    &.is-active {
        opacity: 1;
    }

    &.is-active,
    &:hover {
        background-color: rgba(255, 255, 255, 0.04);
    }

    &__icon {
        display: flex;
        padding: 0 18px;
    }
}
.menu-is-closed .nav-item__label {
    display: none;
}

.logout-button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    transition: 0.2s all;
    background-color: $primary-01;
    cursor: pointer;
    opacity: 0.7;

    .icon {
        padding: 0 18px;
        color: white;
    }

    .label {
        color: $white;
    }

    &.is-active {
        opacity: 1;
    }

    &.is-active,
    &:hover {
        background-color: rgba(255, 255, 255, 0.04);
    }
}
