.setting-edit-view {
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);

    .header {
        padding: $general-side-padding;
        display: flex;
    }

    &.metadata {
        width: 600px;

        .list {
            background-color: $app-bg-color;
            height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;

            .list-header {
                position: relative;

                .add-button {
                    position: absolute;
                    right: 20px;
                    top: 30px;
                }
            }

            .property {
                display: flex;
                background-color: $app-bg-color-light;

                height: 48px;

                &:not(:last-child) {
                    margin-bottom: 4px;
                }

                .property-type {
                    background-color: $primary-01;
                    color: white;
                    padding: 8px;
                    font-size: 16px;
                    text-transform: capitalize;
                    width: 56px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: 4px solid $app-bg-color;
                }

                .property-name {
                    font-size: 20px;
                    padding: 8px;

                    align-items: center;
                    flex-grow: 1;
                    border-right: 4px solid $app-bg-color;
                }

                .property-remove-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 48px;
                    color: $primary-01;
                    cursor: pointer;
                    transition: all 0.2s;

                    &:hover {
                        background-color: $primary-01;
                        color: white;
                    }
                }

                .no-prop-text {
                    text-align: center;
                }
            }

            .save-btn {
                margin-top: 8px;
            }
        }
    }

    &.schemas {
        .header {
        }

        .schema-container {
            background-color: $app-bg-color;
            height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
        }
    }

    .scroll-container .item-container .item .container .name {
        &:hover {
            text-decoration: none;
        }
    }
}
.content {
    .property {
        display: flex;

        .type {
            width: 35%;
            margin-right: 8px;
        }
    }

    .tag-list {
        max-width: 350px;
        .tag-create-container {
            display: flex;
            gap: 8px;
            margin-top: 10px;
            .add-tag-btn {
                height: $list-row-height;
                width: 52px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $app-bg-color-light;
                color: $primary-01;
                cursor: pointer;
                border: $primary-01 1px solid;
                transition: all 0.2s;

                &:hover {
                    background-color: $primary-01;
                    color: white;
                }
            }

            .color-container {
                width: 54px;
                height: 54px;
                cursor: pointer;
                transition: filter 0.2s;

                &:hover {
                    filter: brightness(75%);
                }
            }
        }
    }

    .tags {
        padding-top: 16px;
        display: flex;
        flex-wrap: wrap;
        .tag {
            background-color: red;
            color: white;
            padding: 0 10px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            margin-right: 4px;
            margin-bottom: 4px;
            height: 30px;
            font-size: 13px;

            span {
                filter: brightness(0.8);
            }

            .remove-btn {
                cursor: pointer;
                margin-left: 8px;
                font-size: 18px;
                &:hover {
                    color: grey;
                }
            }
        }
    }
}
