.list-view {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    .search {
        padding: $general-side-padding;
        background-color: $app-bg-color;
    }

    .pagination {
        background-color: $app-bg-color-light;
        border-bottom: 1px solid $border-color;
    }

    .scroll-container {
        background-color: $app-bg-color-light;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;

        &::-webkit-scrollbar-track:vertical {
            margin-top: 52px;
        }

        .sort-toolbar {
            padding-left: $general-side-padding;
            display: flex;
            background-color: $app-bg-color-light;
            border-bottom: 1px solid $border-color;
            position: sticky;
            top: 0;
            z-index: 1;

            .filler {
                min-width: 56px;
                position: sticky;
                right: 0;
                background: white;
            }
            .button {
                height: $list-row-height;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: $app-text-color;
                cursor: pointer;
                transition: all 0.2s;
                text-transform: capitalize;

                .sort-icon {
                    display: flex;
                    align-items: center;
                    margin-left: 4px;
                    width: 16px;

                    img {
                        width: 100%;
                        display: none;
                    }
                    .permanently-visible {
                        display: initial;
                    }
                }

                &:hover {
                    border-bottom: 1px solid $primary-01;
                    color: $primary-01;

                    .visible-on-hover {
                        display: initial;
                    }

                    .permanently-visible {
                        display: none;
                    }
                }

                &.active {
                    border-bottom: 1px solid $primary-01;
                    color: $primary-01;
                }

                &.checkbox {
                    width: 64px;
                    padding: 0px;
                }

                &.schema {
                    min-width: 80px;
                    margin-right: 16px;
                }

                &.type {
                    min-width: 80px;
                    margin-right: 16px;
                }

                &.geometry {
                    min-width: 44px;
                    margin-right: 16px;
                }
                &.name {
                    flex-grow: 1;
                    min-width: 273px;
                    margin-right: 16px;
                }
                &.date {
                    min-width: 120px;
                    padding-right: 16px;
                    background-color: white;
                }
                &.roles {
                    min-width: 110px;
                }
                &.text {
                    min-width: 104px;
                    margin-right: 16px;
                }
                &.table-name {
                    width: 350px;
                    min-width: 300px;
                    margin-right: 16px;
                }
            }
        }

        .item-container {
            flex-grow: 1;
            .item {
                color: $app-text-color;
                user-select: none;
                transition: all 0.2s;
                display: flex;
                padding-left: $general-side-padding;
                background-color: $app-bg-color-light;
                position: relative;
                cursor: pointer;

                &.selected {
                    color: $primary-01;
                    font-weight: 600;
                }

                &:hover {
                    color: $primary-01;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $border-color;
                }
                .container {
                    display: flex;
                    width: 100%;

                    .row-container {
                        flex-grow: 1;
                        background-color: $app-bg-color-light;
                        width: 100%;
                        .row-1 {
                            display: flex;
                            align-items: center;

                            &.checkbox {
                                margin-left: 16px;
                            }

                            .geometry-icon {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                height: $list-row-height;
                                font-size: 14px;
                                min-width: 60px;
                            }

                            &.cached {
                                .geometry-icon {
                                    color: $primary-01;
                                }
                            }

                            &.uncached {
                                .geometry-icon {
                                    color: $error-bg;
                                }
                            }

                            &.caching {
                                .geometry-icon {
                                    color: $warning-bg;
                                }
                            }

                            .button {
                                position: sticky;
                                right: 0;
                                color: #797979;
                                display: flex;
                                align-items: center;
                                transition: all 0.2s;
                                align-self: flex-start;
                                box-shadow: -2px 10px 15px 0 rgba(0, 0, 0, 0.04);
                                background: white;
                                cursor: pointer;

                                .icon {
                                    font-size: 24px;
                                }

                                &:hover {
                                    background-color: #f1f3f4;
                                }

                                svg {
                                    background-color: $neutral-04;
                                    color: black;
                                    margin: 14px 16px;
                                    box-sizing: border-box;
                                }
                            }

                            .checkbox-mark {
                                position: absolute;
                                top: 16px;
                                left: 16px;
                                color: $neutral-03;

                                &.checked {
                                    background: radial-gradient(#d3e2ea 50%, transparent 0, transparent);
                                    color: #024f79;
                                }
                            }
                        }

                        .row-2 {
                            height: 50px;
                            padding-left: 16px;
                            display: flex;
                            align-items: center;
                            background-color: #292e38;

                            .icon {
                                font-size: 16px;
                                margin-right: 8px;
                            }
                        }
                    }

                    .name {
                        background-color: $app-bg-color-light;
                        display: flex;
                        align-items: center;
                        flex-grow: 1;
                        height: $list-row-height;
                        font-size: 14px;
                        transition: all 0.2s;
                        margin-right: 16px;
                        min-width: 273px;

                        .overflow-text {
                            text-overflow: ellipsis;
                            max-width: 315px;
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .schema {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        height: $list-row-height;
                        font-size: 14px;
                        min-width: 80px;
                        color: rgba(0, 0, 0, 0.87);
                        text-align: center;
                        text-transform: capitalize;
                        margin-right: 16px;
                    }

                    .checkbox {
                        min-width: 56px;
                        height: $list-row-height;
                        padding: 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-left: 4px solid $app-bg-color;
                    }

                    .date {
                        height: $list-row-height;
                        display: flex;
                        align-items: center;
                        justify-items: center;
                        font-size: 14px;
                        min-width: 120px;
                        max-width: 120px;
                        margin-right: 16px;
                    }

                    .created {
                        font-size: 12px;
                        margin-right: 24px;

                        &.grow {
                            flex-grow: 1;
                        }
                    }

                    .row-count {
                        margin-right: 24px;
                        font-size: 12px;
                        font-size: 10px;
                    }

                    .roles {
                        min-width: 110px;
                        max-width: 110px;
                        height: $list-row-height;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 14px;
                    }

                    .cached-status {
                        background-color: $primary-01;
                        color: white;
                        padding: 8px;
                        margin-right: 8px;
                        width: 56px;
                        text-align: center;

                        &.cached {
                            background-color: $primary-01;
                        }

                        &.uncached {
                            background-color: $error-bg;
                        }
                    }
                }
            }

            .no-data-indicator {
                height: $list-row-height;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 60px;
            }
        }
    }
    .bottom-container {
        min-height: 48px;
        border-top: 1px solid $border-color;
        background-color: $app-bg-color-light;
        box-sizing: border-box;
    }
}
