.apps-overview {
    width: 700px;
    height: 100%;
    overflow: auto;

    .dataset {
        color: $app-text-color;
        user-select: none;
        transition: all 0.2s;
        display: flex;
        margin-bottom: 4px;
        border-right: 2px solid transparent;

        &:hover {
            cursor: pointer;
            border-color: $primary-01;
        }

        .container {
            display: flex;
            width: 100%;

            .row-container {
                flex-grow: 1;
                background-color: $app-bg-color-light;
                .row-1 {
                    display: flex;
                    align-items: center;

                    .geometry-icon {
                        display: flex;
                        padding: 16px;
                        justify-content: center;
                        align-items: center;
                        background-color: $app-bg-color-light;
                        border-right: 4px solid $app-bg-color;
                    }

                    &.cached {
                        .geometry-icon {
                            color: $primary-01;
                        }
                    }

                    &.uncached {
                        .geometry-icon {
                            color: $error-bg;
                        }
                    }

                    .button {
                        color: #797979;
                        border-left: 4px solid $app-bg-color;
                        display: flex;
                        align-items: center;
                        padding: 16px;
                        transition: all 0.2s;

                        .icon {
                            font-size: 24px;
                        }

                        &:hover {
                            color: white;
                            background-color: $primary-01;
                        }
                    }
                }

                .row-2 {
                    height: 50px;
                    padding-left: 16px;
                    display: flex;
                    align-items: center;
                    background-color: #292e38;

                    .icon {
                        font-size: 16px;
                        margin-right: 8px;
                    }
                }
            }

            .overflow-text {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .name {
                background-color: $app-bg-color-light;
                display: flex;
                align-items: center;
                padding-left: 16px;
                flex-grow: 1;
                height: $list-row-height;
                font-size: 14px;

                .overflow-text {
                    max-width: 250px;
                }
            }

            .schema {
                padding: 0px 8px;
                height: $list-row-height;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                border-right: 4px solid $app-bg-color;
                min-width: 80px;
                max-width: 80px;
                overflow: hidden;
                color: $app-text-color;
                text-align: center;
                text-transform: capitalize;

                .overflow-text {
                    max-width: 80px;
                    text-overflow: clip;
                    white-space: pre-wrap;
                }
            }

            .date {
                padding: 0px 16px;
                height: $list-row-height;
                display: flex;
                align-items: center;
                justify-items: center;
                font-size: 14px;
                border-left: 4px solid $app-bg-color;
                min-width: 100px;
                max-width: 100px;
            }

            .created {
                font-size: 12px;
                margin-right: 24px;

                &.grow {
                    flex-grow: 1;
                }
            }

            .text-column {
                padding: 0px 16px;
                font-size: 14px;
                height: $list-row-height;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 70px;
                max-width: 70px;
                border-left: 4px solid $app-bg-color;
            }

            .database-name {
                padding: 0px 16px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: $list-row-height;
                min-width: 300px;
                max-width: 300px;
                border-left: 4px solid $app-bg-color;
            }
        }
    }
}
