.apps-rootview {
    background-color: $app-bg-color;

    display: grid;
    grid-template-areas: "navigation wrapper map";

    height: 100%;
    width: 100vw;

    .menu-is-closed & {
        grid-template-columns: $sidenav-collapsed-width min-content minmax(20%, 100%);
    }
    .menu-is-open & {
        grid-template-columns: $sidenav-pinned-width min-content minmax(20%, 100%);
    }

    .apps-rootview__wrapper {
        grid-area: wrapper;
        position: relative;
        border-right: 1px solid $border-color;
    }

    .sidebar {
        width: 100%;
        height: 100vh;
        background-color: $app-bg-color;
        display: flex;
        flex-direction: column;
        overflow: auto;

        &.closed {
            width: 0;
            margin-left: 1rem;
            z-index: 0;
        }
    }

    .map {
        grid-area: map;
        position: relative;
    }

    .collapse-button {
        position: absolute;
        right: -14px;
        top: 2rem;
        z-index: 1101;

        :hover {
            cursor: pointer;
        }
    }

    .collapse-button__icon {
        color: rgba(0, 0, 0, 0.8);
        background-color: $app-bg-color-light;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15), 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
    }

    .header {
    }
}
