.redux-toastr {
    .top-left,
    .top-right,
    .top-center,
    .bottom-left,
    .bottom-right,
    .bottom-center {
        width: 389px !important;
        box-sizing: content-box;
    }
    .toastr.toastr {
        min-height: initial;
        padding: 16px;
        opacity: 1;

        .close-toastr {
            display: none;
        }

        .rrt-left-container {
            display: none;
        }

        .rrt-middle-container {
            margin-left: 0;
            padding: 0;
            width: 100%;

            .rrt-text {
                font-size: 14px;
                letter-spacing: 0;
                line-height: 24px;
                display: flex;
                align-items: flex-start;
            }
        }

        &.rrt-success {
            background-color: $success-bg;

            .rrt-text {
                color: $success-text-color;

                &:before {
                    content: "\e5ca";
                    font-family: "Material Icons";
                    font-size: 24px;
                    font-weight: bold;
                    margin-right: 16px;
                }
            }
        }

        &.rrt-error {
            background-color: $error-bg;

            .rrt-text {
                color: $error-text-color;

                &:before {
                    content: "\e000";
                    font-family: "Material Icons";
                    font-size: 24px;
                    margin-right: 16px;
                }
            }
        }

        &.rrt-warning {
            background-color: $warning-bg;

            .rrt-text {
                color: $warning-text-color;

                &:before {
                    content: "\e000";
                    font-family: "Material Icons";
                    font-size: 24px;
                    margin-right: 16px;
                }
            }
        }

        &.rrt-info {
            background-color: $info-bg;

            .rrt-text {
                color: $info-text-color;

                &:before {
                    content: "\e88e";
                    font-family: "Material Icons";
                    font-size: 24px;
                    margin-right: 16px;
                }
            }
        }
    }

    .rrt-confirm-holder {
        .rrt-confirm {
            min-width: 396px;
            .rrt-message {
                text-align: start;
                font-size: 16px;
                font-weight: 600;
                color: $app-text-color;
                line-height: 24px;
                border-bottom: 1px solid $border-color;
                padding: 0 20px 20px;

                &:before {
                    content: "Confirmation Needed";
                    text-align: start;
                    font-size: 18px;
                    font-weight: 900;
                    color: $app-text-color;
                    letter-spacing: 0.79px;
                    line-height: 24px;
                    padding: 20px;
                    margin-bottom: 20px;
                    margin-left: -20px;
                    margin-right: -20px;
                    border-bottom: 1px solid $border-color;
                    display: block;
                }
            }

            .rrt-buttons-holder {
                padding: 20px 24px;

                .rrt-button {
                    height: fit-content;
                    padding: 8px 12px;
                    flex-grow: initial;
                    border-top: none;
                    margin-right: 12px;
                }

                .rrt-button:first-of-type {
                    font-size: 0;
                    background-color: $primary-01;
                    border-radius: 3px;
                    &:hover {
                        background-color: rgba($primary-01, 0.8);
                    }
                    &:active {
                        background-color: rgba($primary-01, 0.6);
                    }
                }

                .rrt-button:last-of-type {
                    color: $primary-01;
                    border-radius: 3px;
                    letter-spacing: 0.61px;
                    line-height: 20px;
                    font-weight: 600;
                    &:hover {
                        background-color: rgba($secondary-01, 0.8);
                    }
                    &:active {
                        color: $primary-01;
                        background-color: rgba($secondary-01, 0.6);
                    }
                }

                .rrt-button:first-of-type:after {
                    content: "Confirm";
                    color: $white;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0.61px;
                    line-height: 20px;
                }
            }
        }
    }
}
