.atlas-dialog {
    .MuiDialog-paper {
        min-width: 350px;
    }

    .dialog-header {
        display: flex;
        align-items: center;
        padding: 16px;
        background-color: $app-bg-color-light;
        border-bottom: 1px solid $border-color;
        .dialog-title {
            background-color: $app-bg-color-light;
            flex-grow: 1;
            margin-right: 16px;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 30px;
        }
    }

    .container {
        background-color: $app-bg-color-light;
        padding: 16px;

        .field {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
}

.MuiDialog-container {
    .MuiTextField-root {
        margin-bottom: 10px;
    }

    ::-webkit-scrollbar {
        width: 20px;
        height: 33px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $neutral-04;
    }

    ::-webkit-scrollbar-track {
        border: white;
    }
}
