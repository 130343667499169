.settings-view {
    width: calc(100vw - #{$sidenav-collapsed-width});
    height: 100%;
    overflow: auto;
    .list-container {
        padding: $general-side-padding;

        .item {
            padding: 16px;
            margin-bottom: 4px;
            background-color: $app-bg-color-light;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-right: 2px solid transparent;

            &:hover {
                border-color: $primary-01;
            }

            .icon {
                margin-right: 16px;
            }
        }
    }
}
